import { Outlet } from "react-router-dom";
import { FriendPanel } from "./FriendPanel";
import { Nav } from "./Nav";
import { MobileBottomNavbar } from "./MobileBottomNav";
import { OnAtLeastMd, OnAtMostMd } from "../breakpoints";

// we can now use routing and have sidebar component act as a factory for what panel to display, when open.
export const SideBar = () => {
  return (
    <>
      <Nav />
      <OnAtLeastMd>
        <FriendPanel />
      </OnAtLeastMd>
      <OnAtMostMd>
        <MobileBottomNavbar />
      </OnAtMostMd>
      <Outlet />
    </>
  );
};
