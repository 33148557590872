// 1. external libraries that are not React components
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/animations/shift-toward.css";
// 2. Internal Libraries, Contexxt & Hooks
import { config } from "../api";
import { useFriendsList } from "../api/queries";
import { usePartyInfoContext } from "../Providers/PartyInfoProvider";
import { useTutorialContext } from "../Providers/TutorialProvider";
import { useValidateInput } from "../hooks/useValidateInput";
import { useAutocomplete } from "../hooks/useAutocomplete";
import { userIdSchema } from "./validations/partyValidation";
// 3. Components
import Tippy from "@tippyjs/react";
import { Icon } from "@iconify/react";
import { FriendList, FriendCard, Loader } from "./index.js";
import { ErrorBoundary } from "./ErrorBoundary";
import { SomethingWentWrong } from "./SomethingWentWrong";
import { AsyncDataStatus } from "./AsyncDataStatus";
import { Autocomplete } from "./Autocomplete";

export const FriendPanel = () => {
  const { addPartyMember, currUser, setCurrUser, cachedUserId } =
    usePartyInfoContext();

  const { tutorialStep, isTutorialModalOpen } = useTutorialContext();

  const {
    inputVal,
    handleChange,
    validateInputString,
    inputError,
    setInputVal,
  } = useValidateInput(userIdSchema, cachedUserId || currUser, false);

  // reset inputVal if cachedUserId changes.
  useEffect(() => {
    setInputVal(cachedUserId);
  }, [cachedUserId]);

  const {
    isFriendListFetching,
    isFriendListFetched,
    isFriendListLoading,
    isFriendListError,
    isFriendListSuccess,
    refetchFriendList,
    friendListErrorMsg,
    friendList,
    currentUser,
  } = useFriendsList(currUser);

  // TODO: Users with same name are getting duplicated for some reason -- figure it out :)
  const friendNames = friendList.reduce(
    (curr, next) => [...curr, next.personaname],
    []
  );

  const { friendSearchInput, onFriendSearchChange, filteredSuggestions } =
    useAutocomplete(friendNames);

  const generateFilteredList = () => {
    let filteredList = [];

    if (friendSearchInput) {
      friendList.map(userObj => {
        filteredSuggestions.map(name => {
          const isDuplicate = !!filteredList.filter(
            friendResult => friendResult.steamId === userObj.steamId
          ).length;
          if (name === userObj.personaname && !isDuplicate) {
            filteredList = [...filteredList, userObj];
          }
        });
      });
      return filteredList;
    }
    return friendList;
  };

  let filteredFriends = generateFilteredList();

  const submitUserId = async e => {
    e.preventDefault();
    // pass ValidateInputString the function you want to run if the value is Valid. In this case, we need to setCurrUser which triggers refetch FriendList.
    validateInputString(setCurrUser);
  };

  useEffect(() => {
    if (currUser) {
      refetchFriendList();
      return;
    } else {
      setCurrUser(cachedUserId);
    }
  }, [currUser]);

  useEffect(() => {
    if (isFriendListSuccess) {
      addPartyMember(currentUser);
    }
  }, [friendList]);

  return (
    <div
      className={`drawer md:min-w-[350px] md:max-w-[350px] bg-steam-background-2 flex flex-col overflow-hidden md:min-h-[100vh] max-h-[60vh] pb-4 md:pb-0 rounded-lg md:rounded-none ${
        isTutorialModalOpen &&
        tutorialStep === 3 &&
        "border-4 border-accent-green-1"
      }`}
    >
      <div
        className={`drawerHeader bg-steam-background-1 p-4 flex flex-col space-y-2 shadow-md ${
          isTutorialModalOpen && tutorialStep === 2
            ? "animate-pulse ring-4 ring-inset ring-accent-green-1"
            : null
        }`}
      >
        <div className="flex flex-row flex-1 items-center justify-between">
          <h1 className="text-2xl font-bold text-copy-green">Create Party</h1>
        </div>
        <form onSubmit={submitUserId}>
          <div className="searchRow flex flex-row gap-2">
            <label className="hidden" htmlFor="idInput">
              Enter a Steam Id
            </label>
            <input
              placeholder="Enter a Steam id"
              type="text"
              className={`rounded-lg py-1 px-2 focus:outline-none focus:ring-1 focus:ring-accent-green-1 font-semibold bg-gray-200 ${
                inputError &&
                "focus:ring-accent-red-1 focus:ring-2 bg-red-100 text-accent-red-1"
              }`}
              id="idInput"
              onChange={handleChange}
              value={inputVal || ""}
            />
            <button type="submit" aria-label="Submit Search">
              <Icon
                className="text-copy-white flex-none"
                icon="bx:search-alt"
                style={{ width: "30px", height: "30px" }}
              />
            </button>
          </div>
        </form>
        <div className="searchRow flex flex-row text-grey-1 italic text-xs">
          {inputError && <span className="text-red-400">{inputError}</span>}
        </div>
      </div>
      <div className="friendSelection"></div>
      <div
        id="friend-list-wrapper-for-tutorial"
        className={`drawerContent relative overflow-hidden${
          isTutorialModalOpen &&
          tutorialStep === 3 &&
          "1 animate-pulse rounded-lg"
        }`}
      >
        <div className="userBox flex flex-col px-4 pt-2 pb-1">
          <ErrorBoundary
            key={currentUser.steamId}
            fallback={<SomethingWentWrong />}
          >
            {isFriendListSuccess && (
              <motion.div initial="hidden" animate="show">
                <FriendCard friend={currentUser} isUser />
                <motion.div
                  initial={{ opacity: 0, translateY: -10 }}
                  animate={{
                    opacity: 1,
                    translateY: 0,
                  }}
                  transition={{ delay: 0.15 }}
                >
                  <div className="flex flex-row mt-4 gap-2">
                    <label className="hidden" htmlFor="friendSearch">
                      Enter a Steam Id
                    </label>

                    <input
                      placeholder="Search friend names"
                      autoComplete="off"
                      type="text"
                      className={`rounded-lg h-6 px-2 py-4 bg-steam-blue-2 border-none text-grey-2 focus:outline-none focus:ring-1 focus:ring-grey-1`}
                      id="friendSearch"
                      value={friendSearchInput}
                      onChange={onFriendSearchChange}
                    />
                  </div>
                </motion.div>
              </motion.div>
            )}
          </ErrorBoundary>
        </div>
        <div className="listOfFriends relative flex flex-col pl-4 overflow-hidden h-[40vmin] md:h-[74vh] md:pb-2 ">
          <div className="customScroll overflow-y-auto pr-4">
            <AsyncDataStatus
              asyncData={{
                isFetching: isFriendListFetching,
                isLoading: isFriendListLoading,
                isSuccess: isFriendListSuccess && filteredFriends.length,
                isError: isFriendListError,
              }}
              defaultRender={() => (
                <div className="text-center mt-2">
                  <span className="text-gray-300">
                    Enter a{" "}
                    <a
                      className="italic hover:text-blue-200 hover:underline text-blue-200"
                      href="https://help.steampowered.com/en/faqs/view/2816-BE67-5B69-0FEC"
                      target="_blank"
                    >
                      Steam Id or URL
                    </a>{" "}
                    to find friends
                  </span>
                  {!cachedUserId && (
                    <div className="text-grey-1 italic flex-col">
                      <span className="font-bold">OR</span>
                      <a href={`${config.API_URL}/auth/steam`}>
                        <img
                          src={require("../icons/steambtn.png")}
                          className="mx-auto mt-2"
                          alt="Steam Sign In"
                          width="180px"
                          height="35px"
                        />
                      </a>
                    </div>
                  )}
                </div>
              )}
              loadRender={() => (
                <div className="flex h-72 justify-center items-center">
                  <Loader />
                </div>
              )}
              successRender={() => <FriendList friendList={filteredFriends} />}
              errorRender={() => (
                <SomethingWentWrong message={friendListErrorMsg} />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
