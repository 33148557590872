import React from "react";

export const useCopyText = (textToCopy, timer = 1500) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return { isCopied, handleCopyClick };
};
