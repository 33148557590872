import { layoutGenerator } from "react-break";

export const breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

const layout = layoutGenerator(breakpoints);

export const OnAtLeastXs = layout.isAtLeast("xs");
export const OnAtLeastSm = layout.isAtLeast("sm");
export const OnAtLeastMd = layout.isAtLeast("md");
export const OnAtLeastLg = layout.isAtLeast("lg");
export const OnAtLeastXl = layout.isAtLeast("xl");
export const OnAtLeastXxl = layout.isAtLeast("xxl");

export const OnAtMostXs = layout.isAtMost("xs");
export const OnAtMostSm = layout.isAtMost("sm");
export const OnAtMostMd = layout.isAtMost("md");
export const OnAtMostLg = layout.isAtMost("lg");
export const OnAtMostXl = layout.isAtMost("xl");
export const OnAtMostXxl = layout.isAtMost("xxl");
