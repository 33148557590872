import React from "react";

function PartyHatGroup({ size = "44", stroke = "black", fill = "white" }) {
  return (
    <svg
      id="h"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M486.19,389.98c-.23-.99-.61-1.97-1.06-2.97l1.06,2.97Z"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M231.28,351.69l2.21-3.43c-.91,1.09-1.65,2.25-2.21,3.43Z"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M229.98,355.74c-3.66,23.13,50.84,50.96,121.69,62.17,70.88,11.23,131.28,1.55,134.96-21.54,.33-2.08,.19-4.2-.4-6.31l-.04-.08-1.06-2.97-83.01-228.25-15.47-48.28-153.17,237.76-2.21,3.43c-.66,1.28-1.08,2.65-1.3,4.05Z"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="18.92"
        />
        <circle
          cx="389.78"
          cy="87.61"
          r="23.12"
          fill={fill}
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M438.38,259.06s-79.53-37.28-85.94-95.9"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M462.91,326.52s-103.06-33.58-146.64-107.22"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M369.32,334.92s-84.69-38.48-79.51-74.55"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <ellipse
          cx="358.3"
          cy="376.06"
          rx="42.38"
          ry="129.91"
          transform="translate(-69.14 671.17) rotate(-81.01)"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
      </g>
      <g>
        <path
          d="M283.61,370.15c-.38-.94-.91-1.85-1.51-2.76l1.51,2.76Z"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M25.85,372.17l1.64-3.74c-.73,1.22-1.28,2.48-1.64,3.74Z"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M25.2,376.38c0,23.42,58.18,42.38,129.91,42.39,71.77,0,129.91-18.99,129.93-42.37,0-2.1-.47-4.17-1.39-6.17l-.05-.07-1.51-2.76-117.67-212.47-22.82-45.26L27.49,368.43l-1.64,3.74c-.45,1.37-.66,2.78-.65,4.21Z"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="18.92"
        />
        <circle
          cx="141.12"
          cy="86.56"
          r="23.12"
          fill={fill}
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M215.92,248.3s-84.38-24.39-99.88-81.29"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M250.7,311.11s-107.05-17.05-161.6-82.98"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M159.57,334.03s-89.66-24.76-90.19-61.2"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <ellipse
          cx="155.12"
          cy="376.38"
          rx="129.91"
          ry="42.38"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
      </g>
      <g>
        <path
          d="M155.57,415.04c.24-.98,.64-1.96,1.1-2.95l-1.1,2.95Z"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M410.9,379.68l-2.17-3.46c.9,1.1,1.62,2.27,2.17,3.46Z"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M412.16,383.74c3.39,23.17-51.42,50.37-122.4,60.77-71.01,10.41-131.29,.05-134.7-23.09-.31-2.08-.14-4.2,.48-6.31l.04-.08,1.1-2.95,85.62-227.28,16.02-48.1,150.42,239.51,2.17,3.46c.64,1.29,1.05,2.66,1.25,4.07Z"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="18.92"
        />
        <circle
          cx="255.44"
          cy="113.79"
          r="23.12"
          fill={fill}
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M204.88,284.67s79.95-36.37,87.04-94.91"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M179.58,351.85s103.44-32.39,147.86-105.53"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <path
          d="M273.06,361.33s85.12-37.5,80.36-73.63"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
        <ellipse
          cx="283.61"
          cy="402.58"
          rx="129.91"
          ry="42.38"
          transform="translate(-55.37 45.37) rotate(-8.34)"
          fill="none"
          stroke={stroke}
          strokeMiterlimit="10"
          strokeWidth="18.92"
        />
      </g>
    </svg>
  );
}

export default PartyHatGroup;
