import { useState } from "react";

export const useValidateInput = (
  schema,
  valToValidate = "",
  resetOnSubmit = true
) => {
  const [inputVal, setInputVal] = useState(valToValidate);
  const [isValid, setIsValid] = useState(true);
  const [inputError, setInputError] = useState("");

  const handleChange = e => {
    setInputError("");
    setInputVal(e.target.value);
  };

  const validateInputString = async func => {
    let trimmedInput = inputVal.trim();
    await schema
      .validate(trimmedInput)
      .then(id => {
        setIsValid(true);
        func && func(trimmedInput);
        if (resetOnSubmit) {
          setInputVal("");
        }
      })
      .catch(err => {
        setIsValid(false);
        setInputError(err.errors[0]);
      });
  };

  return {
    inputVal,
    setInputVal,
    handleChange,
    validateInputString,
    inputError,
    isValid,
  };
};
