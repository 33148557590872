import React from "react";
import colors from "../sass/variables/_colors.module.scss";

function PartyHatSad({ size = "180", stroke = "white", fill = "white" }) {
  return (
    <svg
      id="g"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <path
        d="M487.76,413.84c.83,41.76-87.65,43.11-215.65,45.64-128,2.53-247.05,5.3-247.88-36.46-.83-41.76,102.27-77.67,230.27-80.2,128-2.53,232.44,29.26,233.26,71.02Z"
        fill="none"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <path
        d="M486.46,406.33s-4.75-93.07-34.74-183.29c-26.62-80.08-77.73-180.36-93.73-171.18-73.21,23.53-83.21,17.03-134.67,21.17-5.27,40.18-21.25,106.48-67.06,151.36C61.82,316.93,26.49,411.96,26.49,411.96"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
      <path
        d="M121.62,261.29s238-15.09,297.37-120.62"
        fill="none"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <path
        d="M254.5,342.82s197.68-33.04,204.56-95.99"
        fill="none"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <path
        d="M223.33,73.04l54.62,140.46s74.34-98.54,80.05-161.63"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
      <circle
        cx="277.96"
        cy="254.54"
        r="37.44"
        fill={colors.steamBackground1}
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="20"
      />
    </svg>
  );
}

export default PartyHatSad;
