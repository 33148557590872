import * as React from "react";
import ReactModal from "react-modal";

import { useTutorialContext } from "../Providers/TutorialProvider";
import { usePartyInfoContext } from "../Providers/PartyInfoProvider";

import { useCopyText } from "../hooks/useCopyText";
import { config } from "../api";

import { Icon } from "@iconify/react";
import { FriendCard } from "./FriendList";

ReactModal.setAppElement("#root");

const dummyFriend = {
  steamId: "76561197999688353",
  personaname: "CoolKoala",
  avatar:
    "https://avatars.akamai.steamstatic.com/9cafae15e2da12b491c510a18151726d18c4905e_full.jpg",
  isPrivate: false,
};

export const TutorialModal = ({
  isTutorialModalOpen,
  setIsTutorialModalOpen,
}) => {
  const { tutorialStep, setTutorialStep, nextTutorialStep, prevTutorialStep } =
    useTutorialContext();

  const { cachedUserId } = usePartyInfoContext();
  const { handleCopyClick, isCopied } = useCopyText(cachedUserId);

  function stepButton({ setTutorialStep }) {
    return (
      <button
        onClick={() => setTutorialStep({ setTutorialStep })}
        className={`w-6 h-6 rounded-full ${
          tutorialStep === 2 ? "bg-red-300" : "bg-slate-500"
        }`}
      >
        <span className="m-auto">2</span>
      </button>
    );
  }

  // we could make this a function that returns an array, and takes the 'onclick' as a param. Not necessary, we aren't really losing much performance here.
  const stepsContent = [
    {
      headline: "Welcome to SteamParty",
      contentBody: (
        <span className="mb-auto text-lg leading-8 text-grey-2">
          <span className="text-accent-cyan-1">SteamParty</span> is a super easy
          way to find what Steam games you have in common with your friends.
          Having trouble deciding what to play? We'll pick a game for you.
        </span>
      ),
      buttonText: "Get Started",
    },
    {
      headline: "Enter your steam id",
      contentBody: (
        <div className="text-center text-md">
          <span className="text-gray-300">
            Enter{" "}
            <a
              className="underline hover:text-sky-400 text-sky-200"
              href="https://help.steampowered.com/en/faqs/view/2816-BE67-5B69-0FEC"
              target="_blank"
            >
              your Steam Id or URL
            </a>{" "}
            to find friends
          </span>
          {cachedUserId ? (
            <div>
              <span className="text-accent-cyan-1 block mt-4">
                Your Steam Id:
              </span>
              <button
                onClick={() => {
                  handleCopyClick();
                }}
                className={`flex justify-center items-center mx-auto gap-2 transition duration-500 ${
                  isCopied ? "text-green-200" : "text-white"
                }`}
              >
                <span className="">{cachedUserId}</span>
                <Icon
                  icon="bx:bx-copy"
                  className={`transition duration-500 ${
                    isCopied ? "text-green-200" : "text-white"
                  }`}
                />
              </button>
            </div>
          ) : (
            <div className="text-grey-1 flex-col">
              <span className=" text-white">OR</span>
              <a href={`${config.API_URL}/auth/steam`}>
                <img
                  src={require("../icons/steambtn.png")}
                  className="mx-auto mt-2"
                  alt="Steam Sign In"
                />
              </a>
            </div>
          )}
        </div>
      ),
      buttonText: "next",
    },
    {
      headline: "Create your Party",
      contentBody: (
        <div>
          <span className="mb-auto text-lg leading-8 text-grey-2">
            Select the friends you want to game with!
          </span>
          <FriendCard friend={dummyFriend} isDummy />
        </div>
      ),
      buttonText: "next",
    },
    {
      headline: "Let's Party",
      contentBody: (
        <span className="mb-auto text-lg leading-8 text-grey-2">
          Find your common games (and pick one to play). Having trouble
          deciding? Let us pick for you. Game on!
        </span>
      ),
      buttonText: "Thanks!",
    },
  ];

  const handleModalClose = () => {
    setTutorialStep(1);
    setIsTutorialModalOpen(false);
  };

  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
        content: {
          position: "absolute",
          border: "none",
          left: "50%",
          top: "35%",
          transform: "translateX(-50%)",
          background: "transparent",
          overflow: "visible",
          height: "max-content",
          width: "max-content",
          WebkitOverflowScrolling: "touch",
          padding: 0,
        },
      }}
      isOpen={isTutorialModalOpen}
      onRequestClose={() => handleModalClose()}
    >
      <div
        id="tutorial-content"
        className="tutorialContent overflow-hidden relative bg-steam-blue-1 rounded-lg flex flex-col w-96 border-2 border-steam-background-3"
      >
        <button
          onClick={() => setIsTutorialModalOpen(false)}
          className="absolute top-[.2rem] right-[.2rem] text-3xl text-accent-red-1 hover:rotate-6 hover:scale-110 duration-200 transition-transform ease-in-out"
        >
          <Icon icon="akar-icons:circle-x-fill" />
        </button>
        <div className="bg-steam-background-3 rounded-lg text-copy-white w-full h-fit min-w-96 px-4 py-8">
          <TutorialStepContent
            tutorialStep={tutorialStep}
            setTutorialStep={setTutorialStep}
            nextTutorialStep={nextTutorialStep}
            cachedUserId={cachedUserId}
            setIsTutorialModalOpen={setIsTutorialModalOpen}
            stepContent={stepsContent[tutorialStep - 1]}
          />
        </div>

        <div
          id="tutorial-steps-container"
          className="bg-steam-background-1 z-10 rounded-lg  w-full flex items-center justify-between gap-4 md:gap-8 rounded-b-md px-4"
        >
          <button
            disabled={tutorialStep === 1}
            className="text-3xl text-gray-400 p-2 cursor-pointer  disabled:brightness-50"
            onClick={() => prevTutorialStep()}
          >
            <Icon icon="ant-design:left-circle-filled" />
          </button>
          <div
            id="tutorial-steps"
            className="flex items-center justify-between gap-4 md:gap-8"
          >
            <button
              onClick={() => setTutorialStep(1)}
              className={`w-4 h-4 rounded-full ${
                tutorialStep === 1 ? "bg-accent-green-1" : "bg-slate-500"
              }`}
            ></button>
            <button
              onClick={() => setTutorialStep(2)}
              className={`w-4 h-4 rounded-full ${
                tutorialStep === 2 ? "bg-accent-green-1" : "bg-slate-500"
              }`}
            ></button>
            <button
              onClick={() => setTutorialStep(3)}
              className={`w-4 h-4 rounded-full ${
                tutorialStep === 3 ? "bg-accent-green-1" : "bg-slate-500"
              }`}
            ></button>
            <button
              disabld={tutorialStep === 1}
              onClick={() => setTutorialStep(4)}
              className={`w-4 h-4 rounded-full ${
                tutorialStep === 4 ? "bg-accent-green-1" : "bg-slate-500"
              }`}
            ></button>
          </div>

          <buton
            className="text-3xl text-gray-400 p-2 cursor-pointer"
            onClick={() => nextTutorialStep()}
          >
            <Icon icon="ant-design:right-circle-filled" />
          </buton>
        </div>
      </div>
    </ReactModal>
  );
};

function TutorialStepContent({
  tutorialStep,
  setTutorialStep,
  nextTutorialStep,
  setIsTutorialModalOpen,
  stepContent,
}) {
  const handleTutorialAction = () => {
    setTutorialStep(nextTutorialStep);
    if (tutorialStep === 4) {
      setIsTutorialModalOpen(false);
    }
  };

  return (
    <div className="flex flex-col gap-2 px-4 w-90 h-72">
      <h2 className="text-4xl font-display text-center text-copy-green pb-2 border-b border-grey-1 mb-2">
        {stepContent.headline}
      </h2>
      <div className="text-lg leading-8 text-grey-2 mb-auto">
        {stepContent.contentBody}
      </div>
      <button
        className="p-2 bg-accent-blue-1 mt-auto w-1/2 mx-auto text-steam-blue-2 text-sm rounded-md font-sans uppercase tracking-widest font-bold hover:brightness-110 transition-color duration-500 shadow-md"
        onClick={() => handleTutorialAction()}
      >
        {stepContent.buttonText}
      </button>
    </div>
  );
}
