import React, { useState, useMemo, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import colors from "../sass/variables/_colors.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { staggerListContainer, listItem } from "../motions.js";
import { usePartyInfoContext } from "../Providers/PartyInfoProvider";
import { LockIcon, CheckIcon, EmptyCircleIcon, ShieldIcon } from "../icons";

export const FriendList = ({ friendList }) => {
  return (
    <motion.ul
      initial="hidden"
      animate="show"
      variants={staggerListContainer}
      className="list-none"
    >
      {friendList
        // Todo: This can and should be memoized for sure.
        .sort((a, b) =>
          a.personaname.localeCompare(b.personaname, "en", {
            sensitivity: "base",
          })
        )
        .map(friend => (
          <FriendCard key={friend.steamId} friend={friend} />
        ))}
    </motion.ul>
  );
};

FriendList.propTypes = {
  friendList: PropTypes.arrayOf(PropTypes.object),
};

// StatusIcon
const StatusIcon = ({ isPrivate, isSelected }) => {
  if (isPrivate) {
    return <LockIcon color={colors.accentRed1} size={36} />;
  }
  if (isSelected) {
    return <CheckIcon color={colors.copyGreen} size={36} />;
  }
  return <EmptyCircleIcon color={colors.grey1} size={36} />;
};

// FriendCard
export const FriendCard = ({ friend, isUser, isDummy }) => {
  const { memberIds, addPartyMember, removePartyMember } =
    usePartyInfoContext();
  const [isSelected, setisSelected] = useState(false);

  useEffect(() => {
    if (!isDummy) {
      setisSelected(memberIds.includes(friend.steamId));
    }
  }, [memberIds]);

  const handleSelect = () => {
    if (friend.isPrivate) {
      return;
    }
    if (isDummy) {
      setisSelected(!isSelected);
      return;
    }
    if (isSelected) {
      removePartyMember(friend);
    } else {
      addPartyMember(friend);
    }
  };

  const setSelectedCSS = () => {
    if (friend.isPrivate) {
      return "friendCard--error";
    }
    if (isSelected) {
      return "friendCard--active";
    }
  };

  return (
    <motion.li
      onClick={handleSelect}
      whileHover={{
        y: -2,
        transition: { duration: 0.2, delay: 0, ease: "linear" },
      }}
      className={`friendCard ${setSelectedCSS()} flex justify-start items-center bg-steam-blue-1 p-2 drop-shadow-md cursor-pointer my-3 rounded-lg`}
      variants={listItem}
    >
      <div className="flex justify-between items-center mr-auto">
        <img className="h-12" src={friend.avatar} />
        {friend.isPrivate ? (
          <div className="friendCard__private">
            <span
              className={`friendCard__privateName font-sans text-md text-grey-1 ml-3 truncate w-[10rem]`}
            >
              {friend.personaname}
            </span>
            <span className="friendCard__privateError text-accent-red-1 font-sans text-md mx-3 w-[8.2rem] brightness-110">
              Private User
            </span>
          </div>
        ) : (
          <span
            className={`font-sans text-md ${
              isSelected ? "text-copy-green" : "text-white"
            } mx-3 truncate`}
          >
            {friend.personaname}
          </span>
        )}
      </div>
      <div className="ml-1 mr-2">
        <StatusIcon isPrivate={friend.isPrivate} isSelected={isSelected} />
      </div>
    </motion.li>
  );
};
