export const AsyncDataStatus = ({
  asyncData: { isFetching, isLoading, isSuccess, isError },
  defaultRender,
  loadRender,
  successRender,
  errorRender,
}) => {
  if (isSuccess) {
    return successRender();
  }
  if (isLoading) {
    return loadRender();
  }
  if (isError) {
    return errorRender();
  }
  return defaultRender();
};
