import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

export const useAutocomplete = (fullList = []) => {
  const [friendSearchInput, setFriendSearchInput] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  useEffect(() => {
    const filtered = fullList.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(friendSearchInput.toLowerCase()) > -1
    );
    setFilteredSuggestions(filtered);
  }, [friendSearchInput]);

  const onFriendSearchChange = e => {
    setFriendSearchInput(e.currentTarget.value);
  };

  return { friendSearchInput, onFriendSearchChange, filteredSuggestions };
};
