import React, { useState } from "react";
import ReactDOM from "react-dom";

// styles
import "./index.css";
import "./sass/main.scss";

// providers
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// components
import App from "./App";

const queryClient = new QueryClient(true);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
);
