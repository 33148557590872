import axios from "axios";

export const config = {
  API_URL: process.env.REACT_APP_API_URL,
  GA_KEY: process.env.REACT_APP_GA_KEY,
};

const API = axios.create({ baseURL: config.API_URL });

export const fetchFriends = steamUserId =>
  API.get("/steam/friends", { params: { steamUserId: steamUserId } });

export const fetchSingleUser = steamUserId =>
  API.get("/steam/user", { params: { steamUserId: steamUserId } });

export const fetchCommonGames = steamUserIds =>
  API.get("/steam/getCommonGames", {
    params: { steamUserIds: steamUserIds },
  });
