import React, { useState, useRef, useMemo } from "react";
import { usePartyInfoContext } from "../Providers/PartyInfoProvider";
// Our components
import {
  GameInfoModal,
  SteamCard,
  PartyList,
  Loader,
  TutorialModal,
} from "../components";
import { AsyncDataStatus } from "../components/AsyncDataStatus";
import { SomethingWentWrong } from "../components/SomethingWentWrong";
import { BeerButton } from "../components/BeerButton";
import Logo from "../components/Logo";
import PartyHatGroup from "../icons/PartyHatGroup";
import colors from "../sass/variables/_colors.module.scss";

// Third Party Components / Utilities
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

// Our Utilities
import { OnAtLeastMd, OnAtMostSm } from "../breakpoints";
import { staggerListContainer, listItem, standardShow } from "../motions";
import useScrollToTop from "../hooks/useScrollToTop";
import { FriendPanel } from "../components/FriendPanel";

export const CommonGames = ({ displayState }) => {
  const [selectedGame, setSelectedGame] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollRef = useRef();
  const [isScrollBtnVisible, scrollToTop] = useScrollToTop(scrollRef, 50); // takes a ref to scollTo, and a min height at which to display button

  const {
    isCommonGamesLoading,
    isCommonGamesFetching,
    isCommonGamesError,
    isCommonGamesSuccess,
    commonGames,
    commonGamesErrorMsg,
  } = usePartyInfoContext();

  const memoizedGames = useMemo(
    () =>
      commonGames
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        // TODO: We will need to re-factor this big time if we want dynamic sorting
        .map((game, index) => (
          <motion.div
            onClick={() => openModal(game)}
            key={game.steamAppId}
            className="gameCard w-[130px] lg:w-[140px] xl:w-[180px]"
            variants={listItem}
          >
            <SteamCard gameArt={game.boxart} gameTitle={game.name} />
          </motion.div>
        )),
    [commonGames]
  );

  const openModal = gameInfo => {
    setSelectedGame(gameInfo);
    setIsModalOpen(true);
  };

  const getRandomGame = () => {
    const randomGame =
      commonGames[Math.floor(Math.random() * commonGames.length)];
    setSelectedGame(randomGame);
    return randomGame;
  };

  return (
    <div
      ref={scrollRef}
      className="mainpanel flex-auto bg-steam-background-3 overflow-auto"
      id="mainpanel"
    >
      <GameInfoModal
        selectedGame={selectedGame}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reroll={getRandomGame}
      />

      <div className="contentHeader bg-steam-blue-1">
        <div className="welcomeHeader bg-steam-blue-1 px-4 flex flex-row justify-between shadow-lg items-center">
          <div className="welcomeBox flex-col ml-4 mb-4">
            <div className="welcomeText flex items-center pt-4">
              <span className="uppercase mr-3 font-display text-white text-5xl pt-[6px] hidden lg:block">
                Welcome to
              </span>
              <div className="sm:hidden mr-2">
                <Logo color="copy-green" size="30" />
              </div>
              <div className="hidden sm:block  mr-4">
                <Logo color="copy-green" size="35" />
              </div>
            </div>
            <span className="siteDescription text-grey-1 text-xs hidden lg:block">
              Use SteamParty to find coop / multiplayer games that you and your
              friends have in common
            </span>
          </div>
          <div className="beerButtonBox 2xl:mr-14 lg:mr-4 mr-3 my-3 md:top-0 md:right-4">
            <BeerButton />
          </div>
        </div>
      </div>
      <div className="meatofthepage flex flex-col p-5 2xl:px-16">
        <OnAtMostSm>
          <FriendPanel />
        </OnAtMostSm>
        <OnAtLeastMd>
          <PartyList />
        </OnAtLeastMd>
        {/* Games Panel */}
        <div id="commonGames" className="content-box">
          <div className="header flex items-center gap-6 mb-4">
            <span className="font-display text-2xl text-grey-2">
              Common Games
            </span>
            <button
              disabled={!commonGames.length}
              onClick={() => openModal(getRandomGame(commonGames))}
              className="text-sm px-2 py-2 border border-gray-600 text-white bg-steam-background-1 font-sans hover:brightness-110 transition-color duration-500 hover:text-accent-green-1 shadow-md flex gap-2 items-center justify-center rounded-lg disabled:border-steam-blue-2 disabled:bg-steam-blue-2 disabled:text-grey-1 uppercase"
            >
              <Icon className="text-lg" icon="fa6-solid:dice" />
              <span className="font-sans text-xs">Random</span>
            </button>
          </div>
          <div className="gameInfo flex h-92">
            <AsyncDataStatus
              asyncData={{
                isFetching: isCommonGamesFetching,
                isLoading: isCommonGamesLoading,
                isSuccess: isCommonGamesSuccess,
                isError: isCommonGamesError,
              }}
              defaultRender={() => (
                <div className="flex flex-col h-72 mx-auto justify-center items-center text-white gap-4">
                  <span className="font-bold text-xl text-grey-1">
                    Get your party started!
                  </span>
                  <PartyHatGroup
                    size={100}
                    fill={colors.steamBackground3}
                    stroke={colors.grey2}
                  />
                </div>
              )}
              loadRender={() => (
                <div className="flex h-72 mx-auto justify-center items-center">
                  <Loader />
                </div>
              )}
              successRender={() => (
                <motion.div
                  variants={staggerListContainer}
                  initial="hidden"
                  animate="show"
                  className="flex flex-wrap"
                >
                  {memoizedGames}
                </motion.div>
              )}
              errorRender={() => (
                <div className="flex h-52 mx-auto text-center">
                  <SomethingWentWrong message={commonGamesErrorMsg} />
                </div>
              )}
            />
          </div>
        </div>
      </div>

      {isScrollBtnVisible && (
        <motion.button
          initial={false}
          animate={isScrollBtnVisible ? "show" : "hidden"}
          variants={standardShow}
          className="scrollToTopBtn"
        >
          <Icon
            className="p-2 brightness-150 bg-steam-background-1 border-2 border-steam-blue-3 fixed bottom-44 md:bottom-12 right-14 rounded-full shadow-xl w-12 h-12"
            icon="icon-park:to-top-one"
            onClick={scrollToTop}
          />
        </motion.button>
      )}
    </div>
  );
};

export default CommonGames;
