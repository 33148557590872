import React, { useState } from "react";
import { Helmet } from "react-helmet";
// tools and libs
import ReactGA from "react-ga";

import { fetchCommonGames, config } from "./api";
import axios from "axios";

import { PartyInfoProvider } from "./Providers/PartyInfoProvider";
import { TutorialProvider } from "./Providers/TutorialProvider";

// components
import { breakpoints, OnAtLeastMd } from "./breakpoints";
import { MainPanel, FriendList, SteamCard, SideBar } from "./components";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Nav } from "./components/Nav";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CommonGames } from "./pages/CommonGames";

ReactGA.initialize(config.GA_KEY);

function App() {
  const [showSideBar, setShowSideBar] = useState(true);

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-hidden">
      <Helmet>
        {/* <title>SteamParty - Find Steam Games You And Your Friends Own</title>
        <meta
          name="description"
          content="steamparty.io - find Steam games you and your friends own"
        /> */}
        
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      </Helmet>
      <BrowserRouter>
        <PartyInfoProvider>
          <TutorialProvider>
            <Routes>
              <Route path="/" element={<SideBar />}>
                <Route
                  path="/"
                  element={<Navigate to="/commongames" replace />}
                />
                <Route path="/commongames/" element={<CommonGames />} />
                <Route path="/commongames/:steamId" element={<CommonGames />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </TutorialProvider>
        </PartyInfoProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
