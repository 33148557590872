import React, { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";

import colors from "../sass/variables/_colors.module.scss";

import { useQueryClient } from "react-query";
import { usePartyInfoContext } from "../Providers/PartyInfoProvider";
import { useTutorialContext } from "../Providers/TutorialProvider";
import { useSingleUser } from "../api/queries";
import { useValidateInput } from "../hooks/useValidateInput";
import { userIdSchema } from "./validations/partyValidation";

import { Icon } from "@iconify/react";

export const PartyList = () => {
  const queryClient = useQueryClient();
  const [userToSearch, setUserToSearch] = useState("");
  const [dupMemberError, setDupMemberError] = useState("");

  const {
    partyMembers,
    memberIds,
    addPartyMember,
    removePartyMember,
    resetParty,
    refetchCommonGames,
  } = usePartyInfoContext();

  const { inputVal, handleChange, validateInputString, inputError, isValid } =
    useValidateInput(userIdSchema);

  const { tutorialStep } = useTutorialContext();

  const {
    isSingleUserFetched,
    isSingleUserLoading,
    isSingleUserError,
    isSingleUserSuccess,
    refetchSingleUser,
    singleUser,
    singleUserErrorMsg,
  } = useSingleUser(userToSearch);

  const isMemberDup = !!partyMembers.filter(x => x.steamId === inputVal.trim())
    .length;

  // trim, validate, setUserToSearch, & set error for Dup Member
  const handleSubmit = e => {
    e.preventDefault();
    validateInputString(setUserToSearch);
    partyMembers.map(mem => {
      if (mem.steamId === inputVal.trim()) {
        setDupMemberError(`${mem.personaname} is already in the party`);
      }
    });
  };

  const handleRemove = member => {
    setUserToSearch("");
    queryClient.resetQueries("singleUser", { exact: true });
    removePartyMember(member);
  };

  //TODO: we have to do an extra changehandler, makeshift style since the handlechange is coming from the input hook here. Kinda stepped on our own toes with the input hook here, but can refactor the hook for this usecase later.
  useEffect(() => {
    if (inputVal) {
      setDupMemberError("");
    }
  }, [inputVal]);

  useEffect(() => {
    if (userToSearch && !isMemberDup) {
      refetchSingleUser();
    }
  }, [userToSearch]);

  useEffect(() => {
    if (isSingleUserSuccess) {
      addPartyMember(singleUser);
    }
  }, [singleUser]);

  return (
    <div className="content-box py-4 w-full flex flex-col">
      <div className="flex mb-4 flex-wrap items-center gap-4">
        <span className="font-display text-grey-2 text-2xl">Your Party</span>
        <form onSubmit={handleSubmit} className="flex flex-row gap-2">
          <label className="hidden" htmlFor="friendSearch">
            Enter a Steam Id
          </label>
          <input
            placeholder="Add by Id"
            type="text"
            className={`rounded-lg h-6 px-2 py-4 bg-steam-blue-2 border-none text-grey-1 focus:outline-none focus:ring-1 focus:ring-grey-1
             ${
               inputError &&
               "focus:ring-accent-red-1 focus:ring-2 bg-red-100 text-accent-red-1"
             }`}
            id="friendSearch"
            value={inputVal}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="hover:brightness-110 transition-color duration-200"
            aria-label="Add By Id"
          >
            <Icon
              icon="carbon:add-alt"
              className="text-3xl text-accent-green-1"
            />
          </button>
        </form>
        {inputError && (
          <span className="text-red-400 text-sm">{inputError}</span>
        )}
        {dupMemberError && (
          <span className="text-red-400 text-sm">{dupMemberError}</span>
        )}
      </div>
      <div className="overflow-y-auto rounded-2xl">
        <div className="party-container flex-auto h-48 md:h-full py-2 rounded-lg">
          {partyMembers.length ? (
            <motion.ul
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="party-list flex flex-wrap gap-2 h-max-content min-h-[52px]"
            >
              <AnimatePresence>
                {partyMembers.map(partyMember => (
                  <PartyUserPill
                    partyMember={partyMember}
                    key={partyMember.steamId}
                    handleRemove={handleRemove}
                  />
                ))}
              </AnimatePresence>
            </motion.ul>
          ) : (
            <div className="min-h-[52px] flex items-center">
              <span className="text-grey-1 italic">
                ..Let's get ready to party!
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-4 mt-1 py-2 pr-2">
        <button
          aria-label="Reset & Clear Party Members"
          disabled={!memberIds.length}
          onClick={resetParty}
          className="py-2 px-4 bg-steam-background-1 border border-accent-red-1 font-sans hover:brightness-110 transition-color duration-500 text-accent-red-1 uppercase shadow-md flex gap-2 items-center justify-center rounded-lg disabled:border-steam-blue-2 disabled:bg-steam-blue-2 disabled:text-grey-1"
        >
          <span>Reset</span>
          <Icon className="text-2xl" icon="bx:bx-reset" />
        </button>
        <button
          aria-label="Party & Search Common Games"
          disabled={!(memberIds.length > 1)}
          onClick={refetchCommonGames}
          className={`py-2 px-4 bg-accent-green-1 font-sans hover:brightness-110 transition-color duration-500 text-steam-blue-1 uppercase shadow-md flex gap-2 items-center justify-center rounded-lg disabled:bg-steam-blue-2 disabled:text-grey-1 ${
            tutorialStep === 4
              ? "animate-pulse ring-4 ring-inset ring-accent-green-1"
              : null
          }`}
        >
          <span>Party</span>
          <Icon className="text-2xl" icon="mdi:party-popper" />
        </button>
      </div>
    </div>
  );
};

export const PartyUserPill = ({ partyMember, handleRemove }) => {
  return (
    <motion.li
      key={partyMember.steamId}
      className="friendPill flex min-w-max justify-start items-center bg-steam-blue-1 py-2 pl-4 pr-3 drop-shadow-md mb-2 rounded-full shadow-lg cursor-pointer text-accent-green-1 h-10"
      onClick={() => handleRemove(partyMember)}
      initial={{ opacity: 0, x: -10, y: 0 }}
      whileHover={{ y: -1 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -10 }}
      transition={{ default: { duration: 0.2 } }}
    >
      <span className="font-sans ">{partyMember.personaname}</span>
      <button className="ml-4" type="button">
        <Icon className="text-grey-2 text-2xl" icon="icons8:cancel" />
      </button>
    </motion.li>
  );
};
