import React from "react";
import PartyHatSad from "../icons/PartyHatSad";
import colors from "../sass/variables/_colors.module.scss";

export const SomethingWentWrong = ({ message = "Something Went Wrong" }) => {
  return (
    <div className="my-2  flex flex-col justify-center items-center break-words w-3/4 mx-auto text-center">
      <span className="text-grey-1 font-bold text-lg md:text-2xl">
        {message}
      </span>
      <span className="text-lg md:text-6xl mt-4">
        <PartyHatSad size={90} stroke={colors.grey1} />
      </span>
    </div>
  );
};
