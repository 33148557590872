import React, { useEffect, useState, useRef } from "react";
import { useQueryClient } from "react-query";

import { Icon } from "@iconify/react";

import { usePartyInfoContext } from "../Providers/PartyInfoProvider";
import { useTutorialContext } from "../Providers/TutorialProvider";
import { PartyUserPill } from "./PartyList";
import { AnimatePresence, motion } from "framer-motion";
import { standardShow } from "../motions";

export const MobileBottomNavbar = ({ setMobileStep }) => {
  const queryClient = useQueryClient();
  const { partyMembers, removePartyMember, resetParty, refetchCommonGames } =
    usePartyInfoContext();
  const [showPartyMembers, setShowPartyMembers] = useState(false);
  const [buttonVisibility, setButtonVisibility] = useState({
    leftVis: false,
    rightVis: false,
  });

  const { tutorialStep } = useTutorialContext();

  const scroller = useRef();

  const toggleDrawer = () => {
    setShowPartyMembers(!showPartyMembers);
  };

  const handleRemove = member => {
    queryClient.resetQueries("singleUser", { exact: true });
    removePartyMember(member);
  };

  const onPartyClick = () => {
    scrollToCommonGames();
    refetchCommonGames();
  };

  const onResetClick = () => {
    resetParty();
  };

  const scroll = scrollAmt => {
    scroller.current.scrollBy({ left: scrollAmt, behavior: "smooth" });
  };

  // this is causing weird flicker.. can we do this with refs?
  const scrollToCommonGames = () => {
    document.getElementById("commonGames").scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const scrollListener = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scroller.current;
    const maxScrollWidth = scrollWidth - clientWidth;
    // not scrollable
    if (!(clientWidth < scrollWidth)) {
      setButtonVisibility({ leftVis: false, rightVis: false });
    }
    // max scroll
    else if (clientWidth < scrollWidth && scrollLeft > maxScrollWidth - 15) {
      setButtonVisibility({ leftVis: true, rightVis: false });
    }
    // scrollable, and a little bit scrolled
    else if (scrollLeft >= 5 && clientWidth < scrollWidth) {
      setButtonVisibility({ leftVis: true, rightVis: true });
    }
    // not scrolled at all
    else if (scrollLeft < 5 && clientWidth < scrollWidth) {
      setButtonVisibility({ leftVis: false, rightVis: true });
    } else {
      setButtonVisibility({ leftVis: false, rightVis: false });
    }
  };

  // scroll listener not only needs to run on scroll via event listener, but also when partymembers.length changes
  useEffect(() => {
    if (!partyMembers.length) {
      setShowPartyMembers(false);
    }
    if (scroller && scroller.current) {
      scrollListener();
    }
    if (partyMembers.length < 3) {
      setButtonVisibility({ leftVis: false, rightVis: false });
    }
  }, [partyMembers.length, showPartyMembers]);

  // use scroll event listener to trigger left or right button visibility on scroll
  useEffect(() => {
    if (scroller && scroller.current) {
      scroller.current.addEventListener("scroll", scrollListener, false);
      return function cleanup() {
        scroller.current?.removeEventListener("scroll", scrollListener, false);
      };
    }
  }, []);

  return (
    <div className="md:hidden overflow-hidden w-full">
      <div
        className={`mobilePartyDrawer ${
          showPartyMembers && "mobilePartyDrawer--active"
        } bg-steam-blue-2 overflow-hidden flex justify-center items-start pt-2`}
      >
        <div ref={scroller} className="partyList overflow-x-auto">
          {partyMembers.length ? (
            <motion.ul
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-screen px-6 flex flex-nowrap gap-2 h-max-content min-h-[52px] items-center pt-[.2rem]"
            >
              <AnimatePresence>
                {partyMembers.map(
                  partyMember =>
                    showPartyMembers && (
                      <PartyUserPill
                        key={partyMember.steamId}
                        partyMember={partyMember}
                        handleRemove={handleRemove}
                      />
                    )
                )}
              </AnimatePresence>
            </motion.ul>
          ) : (
            <div className="text-grey-1 font-display text-2xl">
              Your Party is Empty
            </div>
          )}
        </div>
        {buttonVisibility.leftVis && (
          <div className="navControl navControl--left">
            <button
              onClick={() => scroll(-300)}
              className="navControl__btn text-3xl text-gray-400 mb-2"
            >
              <Icon icon="ant-design:left-circle-filled" />
            </button>
          </div>
        )}
        {buttonVisibility.rightVis && (
          <div className="navControl navControl--right">
            <button
              onClick={() => scroll(300)}
              className="navControl__btn text-3xl text-gray-400 mb-2"
            >
              <Icon icon="ant-design:right-circle-filled" />
            </button>
          </div>
        )}
      </div>
      <div className="mobileNav fixed bottom-0 h-20 w-full bg-steam-background-1 flex justify-end px-4">
        <div className="relative flex items-center gap-6 ">
          <button
            disabled={!partyMembers.length}
            onClick={onResetClick}
            className="py-2 px-4 bg-steam-background-1 border border-accent-red-1 font-sans hover:brightness-110 transition-color duration-500 text-accent-red-1 uppercase shadow-md flex gap-2 items-center justify-center rounded-lg disabled:border-steam-blue-2 disabled:bg-steam-blue-2 disabled:text-grey-1"
          >
            <span>RESET</span>
            <Icon className="text-2xl" icon="bx:bx-reset" />
          </button>
          <button
            disabled={partyMembers.length < 2}
            onClick={onPartyClick}
            className={`py-2 px-4 bg-accent-green-1 font-sans hover:brightness-110 transition-color duration-500 text-steam-blue-1 shadow-md flex gap-2 items-center justify-center rounded-lg disabled:bg-steam-blue-2 disabled:text-grey-1 ml-auto font-semibold ${
              tutorialStep === 4
                ? "animate-pulse ring ring-inset ring-accent-green-1"
                : null
            }`}
          >
            <span>PARTY</span>
            <Icon className="text-2xl" icon="mdi:party-popper" />
          </button>
          <button onClick={toggleDrawer} aria-label="Open Party">
            <div className="relative h-12 w-12 rounded-full border border-accent-green-1 flex items-center justify-center">
              {partyMembers.length > 0 && (
                <div className="absolute top-[-.3rem] left-[-10px] flex justify-center items-center font-bold text-steam-blue-2 rounded-full w-6 h-6 bg-accent-green-1 text-lg">
                  {partyMembers.length}
                </div>
              )}
              <Icon
                className="text-grey-2 hover:brightness-125 transition-color duration-500 text-3xl"
                icon="ph:users-four-bold"
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
