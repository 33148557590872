import React from "react";

function Logo({ color = "white", size = "50" }) {
  return (
    <svg
      className={`fill-${color} stroke-${color}`}
      // className="fill-copy-green stroke-copy-green"
      xmlns="http://www.w3.org/2000/svg"
      width={size * 6.39}
      height={size}
      viewBox="0 0 786.56 123.03"
    >
      <title>Steam Party</title>
      <path
        className="stroke-1"
        d="M0,88.82V82.89H15.43V90c0,7.42,3.27,10.09,8.46,10.09s8.61-2.67,8.61-10.39c0-8.6-3.56-13.95-14.25-23.3C4.6,54.39,0,45.79,0,33.91,0,17.59,8.46,8.09,24.34,8.09s23.74,9.5,23.74,26.12v4.3H32.65V33.17c0-7.42-3-10.24-8.16-10.24s-8.31,2.82-8.31,9.94c0,7.57,3.71,12.92,14.39,22.27,13.65,12,18.11,20.48,18.11,33.39,0,16.92-8.61,26.41-24.64,26.41S0,105.45,0,88.82Z"
        fill={color}
      />
      <path d="M81.62,24.42H64.56V9.57H115V24.42H98v89H81.62Z" fill={color} />
      <path
        d="M134,9.57h44.52V24.42h-28.2V51.87h22.41V66.71H150.33V98.62h28.2v14.84H134Z"
        fill={color}
      />
      <path
        d="M231.36,93.13H212.51l-2.81,20.33H194.56L211.48,9.57h22.11l16.92,103.89H234.18ZM229.29,79l-7.43-52.39L214.59,79Z"
        fill={color}
      />
      <path
        d="M269.35,9.57h23L303.19,85,314,9.57h23V113.46H321.59V34.8l-12.17,78.66H295.77L283.6,34.8v78.66H269.35Z"
        fill={color}
      />
      <path
        d="M368.33,9.59H397.7c20.31,0,34.92,12.57,34.92,35.07v3.79c0,22.5-14.61,34.34-34.92,34.34H377v29.8h-8.62Zm29.08,64.87C412.75,74.46,424,66,424,48.31V44.66c0-18.85-11.69-26.59-26.59-26.59H377V74.46Z"
        fill={color}
      />
      <path
        d="M541.9,9.59h29.81c20.6,0,34.48,12.13,34.48,33.75v3.8c0,18.26-11,28.93-26.88,31.27l26.59,34.18H595.52l-25-32.72h-20v32.72H541.9Zm29.08,62c15.34,0,26.59-7.89,26.59-24.4V43.05c0-17.39-11.1-25-26.59-25H550.52V71.54Z"
        fill={color}
      />
      <path
        d="M653.18,18.07H624.69V9.59h65.74v8.48H661.8v94.52h-8.62Z"
        fill={color}
      />
      <path
        d="M742.58,72.71,707.37,9.59h9.35l30.1,54.64L777.36,9.59h9.2L751.2,72.71v39.88h-8.62Z"
        fill={color}
      />
      <ellipse
        cx="474.59"
        cy="103.55"
        rx="13.95"
        ry="42.76"
        transform="translate(283.63 550.19) rotate(-79.16)"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="6.03"
      />
      <polyline
        points="516.63 110.18 495.47 18.16 485.29 31.38 433.42 93.6"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.23"
      />
      <circle
        cx="497.05"
        cy="10.72"
        r="7.61"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="6.23"
      />
      <path
        d="M464.49,58.38s27.07-2.66,35.61-20.1"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="6.23"
      />
      <path
        d="M447.65,76.53s34.9,1.56,56.61-16.37"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="6.23"
      />
      <path
        d="M475.69,89.58s30.51-2.45,32.94-14.2"
        fill="none"
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="6.23"
      />
    </svg>
  );
}

export default Logo;
