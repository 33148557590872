import React from "react";
import { Icon } from "@iconify/react";

export function BeerButton() {
  return (
    <div className="beerBtn">
      <i className="beerBtn__handle sm:h-[40px] h-[30px]"></i>
      <button
        onClick={() =>
          window.open("https://buymeacoffee.com/VPlrZaoVMG", "_blank")
        }
        className="beerBtn__glass sm:h-[80px] h-[60px]"
      >
        <span className="font-display buyUs sm:text-md text-sm tracking-wide">
          buy us
        </span>
        <span className="font-display beer sm:text-lg text-sm tracking-wider">
          beer?
        </span>
        <i className="beerBtn__liquid"></i>
      </button>
    </div>
  );
}

export default BeerButton;
