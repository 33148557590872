import React from "react";

function LockIcon({ size = "44", color = "white" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width={size}
      height={size}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 15 15"
    >
      <g fill="none">
        <path
          d="M5.5 7h4V6h-4v1zm4.5.5v3h1v-3h-1zM9.5 11h-4v1h4v-1zM5 10.5v-3H4v3h1zm.5.5a.5.5 0 0 1-.5-.5H4A1.5 1.5 0 0 0 5.5 12v-1zm4.5-.5a.5.5 0 0 1-.5.5v1a1.5 1.5 0 0 0 1.5-1.5h-1zM9.5 7a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 9.5 6v1zm-4-1A1.5 1.5 0 0 0 4 7.5h1a.5.5 0 0 1 .5-.5V6zm.5.5v-1H5v1h1zm3-1v1h1v-1H9zM7.5 4A1.5 1.5 0 0 1 9 5.5h1A2.5 2.5 0 0 0 7.5 3v1zM6 5.5A1.5 1.5 0 0 1 7.5 4V3A2.5 2.5 0 0 0 5 5.5h1zm-5 2A6.5 6.5 0 0 1 7.5 1V0A7.5 7.5 0 0 0 0 7.5h1zM7.5 14A6.5 6.5 0 0 1 1 7.5H0A7.5 7.5 0 0 0 7.5 15v-1zM14 7.5A6.5 6.5 0 0 1 7.5 14v1A7.5 7.5 0 0 0 15 7.5h-1zm1 0A7.5 7.5 0 0 0 7.5 0v1A6.5 6.5 0 0 1 14 7.5h1z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default LockIcon;
