import React, { useState } from "react";
import ReactModal from "react-modal";
import { Icon } from "@iconify/react";

ReactModal.setAppElement("#root");

export const GameInfoModal = ({
  selectedGame,
  isModalOpen,
  setIsModalOpen,
  reroll,
}) => {
  const [imageError, setImageError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const handleError = e => {
    const placeholderImageUrl =
      "https://images.unsplash.com/photo-1586182987320-4f376d39d787?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80";

    if (e.target.src !== placeholderImageUrl) {
      setImageError(true);
      e.target.src = placeholderImageUrl;
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(selectedGame.steamUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2500);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          position: "absolute",
          border: "none",
          left: "50%",
          top: "25%",
          transform: "translateX(-50%)",
          background: "transparent",
          overflow: "visible",
          height: "max-content",
          width: "max-content",
          WebkitOverflowScrolling: "touch",
          padding: 8,
        },
      }}
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <div className="relative bg-steam-blue-1 rounded-xl p-6 flex w-fit h-fit -z-[1]">
        <button
          onClick={() => setIsModalOpen(false)}
          className="absolute top-[-.4rem] right-[-.5rem] text-3xl text-accent-red-1 hover:rotate-6 hover:scale-125 duration-200 transition-transform ease-in-out"
        >
          <Icon icon="akar-icons:circle-x-fill" />
        </button>
        <div className="">
          {imageError && (
            <span className="absolute w-full text-white text-center text-xl top-10 px-4 text-shadow-lg">
              {selectedGame.gameTitle}
            </span>
          )}
          <img
            className="absolute top-0 left-0 right-0 bottom-0 blur-sm lg:blur-none opacity-25 lg:opacity-100 w-full lg:relative -z-[1] lg:block object-cover rounded-md h-full lg:w-48"
            onError={e => handleError(e)}
            src={selectedGame.boxart}
          />
        </div>
        <div className="flex flex-col lg:px-6 max-w-[250px] lg:max-w-[350px]">
          <div className="flex items-center justify-between">
            <h3 className="text-3xl text-grey-2 font-display flex-auto mb-3">
              {selectedGame.name}
            </h3>
          </div>
          <div className="border-t border-grey-1 w-full h-full flex flex-col gap-3">
            <div className="mt-2 lg:mt-0 lg:pt-2 lg:pb-auto p-2 lg:p-0 bg-gray-800 bg-opacity-50 lg:bg-opacity-0 rounded-md">
              <p className="text-grey-1">{selectedGame.shortDescription}</p>
            </div>
            <div className="steamInputCopy flex gap-2">
              <input
                value={selectedGame.steamUrl}
                className="bg-grey-1 text-steam-background-1 placeholder-slate-600 rounded-lg "
              />
              <button
                className="py-1 px-4 bg-accent-blue-1 font-sans hover:brightness-110 transition-color duration-500 text-steam-blue-1 uppercase shadow-md flex gap-2 items-center justify-center text-sm rounded-lg disabled:bg-steam-blue-2 disabled:text-grey-1 font-bold"
                onClick={handleCopyClick}
              >
                {!isCopied ? (
                  <div className="flex justify-center items-center gap-2">
                    <span>Copy</span>
                    <Icon icon="bx:bx-copy" />
                  </div>
                ) : (
                  "Copied!"
                )}
              </button>
            </div>
            <button
              className="py-3 px-4 bg-accent-green-1 font-sans hover:brightness-110 transition-color duration-500 text-steam-blue-1 uppercase shadow-md flex gap-2 items-center justify-center text-sm rounded-lg disabled:bg-steam-blue-2 disabled:text-grey-1 font-bold"
              onClick={reroll}
            >
              <Icon icon="fa6-solid:dice" />
              <span>Pick a random game</span>
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
