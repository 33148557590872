import { React, useState } from "react";

import { useNavigate } from "react-router-dom";

import Tippy from "@tippyjs/react";
import { Icon } from "@iconify/react";

import Logo from "./Logo";
import { TutorialModal } from "./TutorialModal";
import { usePartyInfoContext } from "./../Providers/PartyInfoProvider";
import { useTutorialContext } from "./../Providers/TutorialProvider";

import { config } from "../api";

export const Nav = () => {
  const { isTutorialModalOpen, setIsTutorialModalOpen } = useTutorialContext();
  const { currUser, cachedUserId, clearCachedUser } = usePartyInfoContext();

  return (
    <div className="flex flex-row md:flex-col justify-end md:justify-between items-center md:w-16 p-4 bg-steam-background-1 gap-4">
      <TutorialModal
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
      />
      <div className="mr-auto md:hidden">
        <Logo size={20} />
      </div>
      <div className="icons flex flex-row md:flex-col md:space-y-2 gap-5">
        <div>
          <Tippy
            animation="shift-toward"
            placement="left"
            content="Quick Tutorial"
          >
            <button
              className="w-8 h-8"
              onClick={() => setIsTutorialModalOpen(true)}
              aria-label="Open Tutorial"
            >
              <Icon
                className="text-copy-green hover:brightness-125 transition-color duration-500"
                // icon="ph:users-three-bold"
                icon="ant-design:question-circle-outlined"
                style={{ width: "100%", height: "100%" }}
              />
            </button>
          </Tippy>
        </div>
        <a
          className="w-8 h-8"
          target="_blank"
          href="https://discord.gg/tycff4vcD2"
        >
          <Tippy
            animation="shift-toward"
            placement="right"
            content="Join our Discord!"
          >
            <button className="w-full" aria-label="Discord">
              <Icon
                className="hover:brightness-125 transition-color duration-500"
                icon="bx:bxl-discord-alt"
                style={{ width: "100%", height: "100%", color: "#7983F5" }}
              />
            </button>
          </Tippy>
          <span className="sr-only">Discord</span>
        </a>
        {!cachedUserId ? (
          <a className="w-8 h-8" href={`${config.API_URL}/auth/steam`}>
            <Tippy
              animation="shift-toward"
              placement="right"
              content="Sign in with Steam"
            >
              <button className="w-full" aria-label="Steam Sign In">
                <Icon
                  className="hover:brightness-125 transition-color duration-500"
                  icon="bi:steam"
                  style={{ width: "100%", height: "100%", color: "#c7d5e0" }}
                />
              </button>
            </Tippy>
            <span className="sr-only">Steam Sign In</span>
          </a>
        ) : (
          <Tippy animation="shift-toward" placement="right" content="Sign Out">
            <button
              onClick={() => {
                clearCachedUser();
              }}
              className="w-8 h-8"
              aria-label="Sign Out and Clear Steam Id"
            >
              <Icon
                className="hover:brightness-125 transition-color duration-500"
                icon="la:sign-out-alt"
                style={{ width: "100%", height: "100%", color: "#c7d5e0" }}
              />
            </button>
          </Tippy>
        )}
      </div>
    </div>
  );
};
