import { fetchCommonGames, fetchFriends, fetchSingleUser } from "./index.js";
import { useQuery } from "react-query";

export const useFriendsList = userId => {
  const {
    data,
    isFetched,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    refetch,
    status,
    error,
  } = useQuery(["friends", userId], () => fetchFriends(userId), {
    enabled: false,
  });

  return {
    isFriendListLoading: isLoading,
    isFriendListFetched: isFetched,
    isFriendListFetching: isFetching,
    isFriendListError: isError,
    isFriendListSuccess: isSuccess,
    refetchFriendList: refetch,
    friendList: data?.data?.friends || [],
    currentUser: data?.data?.currentUser || "",
    friendListStatus: status,
    friendListErrorMsg:
      error?.response?.data.error.message ?? "Something Went Wrong",
  };
};

export const useSingleUser = userId => {
  const {
    data,
    isFetched,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    refetch,
    status,
    error,
  } = useQuery("singleUser", () => fetchSingleUser(userId), {
    enabled: false,
  });

  return {
    isSingleUserLoading: isLoading,
    isSingleUserFetched: isFetched,
    isSingleUserFetching: isFetching,
    isSingleUserError: isError,
    isSingleUserSuccess: isSuccess,
    refetchSingleUser: refetch,
    singleUser: data?.data || "",
    singleUserStatus: status,
    singleUserErrorMsg:
      error?.response?.data?.error.message ??
      "Something went wrong when we searched for that user",
  };
};

export const useCommonGames = userIds => {
  const {
    data,
    isFetched,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    refetch,
    status,
    error,
  } = useQuery("commonGames", () => fetchCommonGames(userIds), {
    enabled: false,
  });

  return {
    isCommonGamesLoading: isLoading,
    isCommonGamesFetched: isFetched,
    isCommonGamesFetching: isFetching,
    isCommonGamesError: isError,
    isCommonGamesSuccess: isSuccess,
    refetchCommonGames: refetch,
    commonGames: data?.data?.commonGames ?? [],
    commonGamesStatus: status,
    commonGamesErrorMsg:
      error?.response.data.error.message ?? "Something Went Wrong",
  };
};
