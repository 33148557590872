import { useState } from "react";
import usehandleImageError from "../hooks/useHandleImageError";

export const SteamCard = ({ gameArt, gameTitle }) => {
  const [imageError, setImageError] = useState(false);
  const handleError = e => {
    const placeholderImageUrl =
      "https://images.unsplash.com/photo-1586182987320-4f376d39d787?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80";

    if (e.target.src !== placeholderImageUrl) {
      setImageError(true);
      e.target.src = placeholderImageUrl;
    }
  };

  // const imageError = usehandleImageError();

  return (
    <div className="steamCard">
      <div className="steamCard__side">
        {imageError && (
          <span className="absolute w-full text-white text-center text-xl top-10 px-4 text-shadow-lg">
            {gameTitle}
          </span>
        )}
        <img
          className="steamCard__image"
          src={gameArt}
          onError={e => handleError(e)}
          // onError={(e) => usehandleImageError(e)}
          alt={gameTitle}
        />
      </div>
    </div>
  );
};

export default SteamCard;
