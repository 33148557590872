export const staggerListContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    translateX: 0,
    transition: {
      staggerChildren: 0.06,
    },
  },
};

export const listItem = {
  hidden: { opacity: 0, translateY: -10 },
  show: { opacity: 1, translateY: 0 },
};

export const standardShow = {
  hidden: {
    opacity: 0,
    transition: {
      ease: "easeOut",
    },
  },
  show: {
    opacity: 1,
    transition: {
      ease: "easeIn",
    },
  },
};
