import { useState, useEffect, useContext, createContext } from "react";

export const TutorialContext = createContext();

export function TutorialProvider({ children }) {
  const [tutorialStep, setTutorialStep] = useState(1);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);

  const nextTutorialStep = () => {
    if (tutorialStep !== 4) {
      setTutorialStep(tutorialStep + 1);
      return;
    }
    setTutorialStep(1);
  };

  const prevTutorialStep = () => {
    if (tutorialStep === 1) {
      return;
    }
    setTutorialStep(tutorialStep - 1);
  };

  const contextValues = {
    tutorialStep,
    setTutorialStep,
    nextTutorialStep,
    prevTutorialStep,
    isTutorialModalOpen,
    setIsTutorialModalOpen,
  };

  return (
    <TutorialContext.Provider value={contextValues}>
      {children}
    </TutorialContext.Provider>
  );
}

export const useTutorialContext = () => useContext(TutorialContext);
