import { useState, useEffect, useContext, createContext } from "react";
import { useQueryClient } from "react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useCommonGames } from "../api/queries";

import _ from "lodash";

export const PartyInfoContext = createContext();

// Hook
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export const PartyInfoProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [urlParams] = useSearchParams();
  const [currUser, setCurrUser] = useState("");
  const urlId = urlParams.get("steamId");
  const [cachedUserId, setCachedUserId] = useLocalStorage("steamId");
  const [partyMembers, setPartyMembers] = useState([]);

  const populateMemberIds = function () {
    return partyMembers.reduce((ptyMems, next) => {
      return [...ptyMems, next.steamId];
    }, []);
  };

  const memberIds = populateMemberIds();

  const navigate = useNavigate();

  // check for the 3 possible login states on component mount.
  useEffect(() => {
    if (!urlId && !cachedUserId) {
      return;
    }
    // user has just signed in, but value not yet cached.
    if (urlId && !cachedUserId) {
      setCachedUserId(urlId);
    }
    // no url param, but local storage does exist!
    if (!urlId && cachedUserId) {
      //setCurrUser to that id from local storage
      setCurrUser(cachedUserId);
    }
  }, []);

  useEffect(() => {
    populateMemberIds();
  }, [partyMembers]);

  const addPartyMember = friend => {
    const isDuplicatePartyMember = !!partyMembers.filter(
      f => f.steamId === friend.steamId
    ).length;
    if (isDuplicatePartyMember || !friend) {
      return;
    }
    setPartyMembers([...partyMembers, friend]);
  };

  const removePartyMember = memberToRemove => {
    const newPartyMembers = partyMembers.filter(
      member => member.steamId !== memberToRemove.steamId
    );
    setPartyMembers(newPartyMembers);
  };

  const resetParty = () => {
    setPartyMembers([]);
    queryClient.resetQueries("commonGames", { exact: true });
  };

  // this is jank, but its ok for now :)
  const clearCachedUser = () => {
    navigate("/");
    window.localStorage.removeItem("steamId");
    document.location.reload();
  };

  const {
    isCommonGamesLoading,
    isCommonGamesFetched,
    isCommonGamesFetching,
    isCommonGamesError,
    isCommonGamesSuccess,
    refetchCommonGames,
    commonGames,
    commonGamesErrorMsg,
  } = useCommonGames(_.map(partyMembers, "steamId").join(","));

  const contextValues = {
    currUser,
    setCurrUser,
    clearCachedUser,
    cachedUserId,
    partyMembers,
    memberIds,
    addPartyMember,
    removePartyMember,
    resetParty,
    isCommonGamesLoading,
    isCommonGamesFetched,
    isCommonGamesFetching,
    isCommonGamesError,
    isCommonGamesSuccess,
    refetchCommonGames,
    commonGames,
    commonGamesErrorMsg,
  };

  return (
    <PartyInfoContext.Provider value={contextValues}>
      {children}
    </PartyInfoContext.Provider>
  );
};

export const usePartyInfoContext = () => useContext(PartyInfoContext);
